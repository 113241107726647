.containerPrincipal{
    position:absolute;
    border:  1px solid blue;
    background-color: white;
    padding: 40px;
    width:90%;
}
.footer {
    background-color: rgba(0, 0, 0, .5);
    bottom: 0;
    position: fixed;
    width: 100%;
    padding: 20px;
  }