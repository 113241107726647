.containerPrincipal{
    position:absolute;
    left: 50%;
    top: "50px";
    transform: translate(-50%,50%);
    border:  1px solid blue;
    background-color: white;
    padding: 40px;
}
.containerSecundario{
    text-align: center;
}