.Cabecera
{
    width: 100%;
    height: 40px;
    background-color: #0094D9;
    color: white;
    position: absolute;
    top: 0px;
    z-index: 1000;
    text-align: right;
    padding: 5px;
    font-family:Verdana;
    font-size: large;
}
.Notificacion
{
    flex-direction:row-reverse;
             width:"400px";
             background-color:"blue";
             height:"100px";
             margin-top:"40px";
    z-index: 10000;    

}